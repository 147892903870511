.card {
  height: 320px;
  width: min(90%, 350px);
  padding: 2rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 1rem;
  background-color: var(--clr-white-100);

  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#avatar-review {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.card p, .card h3 {
  text-align: center;
  color: var(--clr-primary-400);
  font-weight: normal;
  font-style: italic;
}

.card span {
  color: var(--clr-sage-100);
  font-weight: bold;
}
