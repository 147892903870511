.banner {
  /* background-image: url("../../Assets/Img/BackgroundImgReflexo2.jpg"); */
  background-image: url("../../Assets/Img/banner01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  height: 100vh;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}


.container-banner h1 {
  margin: 0;
  margin-bottom: 10px;
  color: var(--clr-cream-100);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
  font-weight: bold;
  letter-spacing: 0.1em;
}

.container-banner p {
  margin: 2rem 0;
  color: var(--clr-cream-100);
  text-shadow: 1px 1px 3px rgba(0,0,0,0.2);
}

@media (min-width: 1000px) {
  .container-banner h1 {
    font-size: 45px;
  }

  .container-banner p {
    font-size: 25px;
  }
}

@media (max-width: 650px) {

  .container-banner p {
    margin: 5rem 0;
  }

}

@media (max-width: 434px) {

  .container-banner h1 {
    font-size: 35px;
  }

  .container-banner p {
    font-size: 20px;
  }


}
