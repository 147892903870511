.card-session {
  /* height: 40rem; */
  height: 33rem;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  background-color: var(--clr-white-200);
}

.card-session img {
  height: 100%;
  /* width: 30rem; */
  width: 45rem;
  object-fit: cover;
}


.card-session h2 {
  font-weight: bold;
}

.card-session p {
  margin-bottom: 0;
  margin-top: 8px;
}

.card-session-infos {
  padding: 16px;
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
}

.column-session {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem 3rem;
}


@media (max-width: 1325px) {

  .card-session img {
    height: 100%;
    /* width: 17rem; */
    width: 27rem;
    object-fit: cover;
  }

}

@media (max-width: 1180px) {
  .card-session {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--clr-white-200);
  }

  .column-session {
    margin-bottom: 5rem;
  }

  .card-session img {
    display: none;
  }

}


@media (max-width: 680px) {
  .card-session {
    height: 100%;
    width: 100%;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--clr-white-200);
  }

}
