.reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 55px;
}

@media (min-width: 1200px) {
  .reviews {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 55px;
  }
}
