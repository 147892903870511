.img-care {
  border-radius: 999px;
  width: 20rem;
  height: 46rem;
  object-fit: cover;
}

@media (max-width: 924px) {
  .img-care {
    display: none;
  }

}
