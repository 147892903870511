.navbar {

  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 5rem;


  background-color: transparent;
  display: flex;
  justify-content: center;

  /* padding-inline: 1.25rem; */
  padding: 1.25rem;

  transition: 0.3s ease-in-out;
}

.navbar-fixed {
  width: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  /* height: auto; */
  height: 5rem;

  background-color: var(--clr-primary-400);
  display: flex;
  justify-content: center;

  /* padding-inline: 1.25rem; */
  padding: 1.25rem;

  transition: 0.3s ease-in-out;

}
