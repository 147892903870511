.notFound {
  background-color: var(--clr-white-200);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-notFound {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3rem;
  text-align: center;
}

.content-notFound span {
  font-size: 10rem;
}
