.form-control {
  margin-bottom: 1rem;
  float: left;
  width: 90%;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: left;
  width: 90%;
}

.form-control input,
.form-control textarea,
.form-control label {
  display: block;
  box-sizing: border-box;
  resize: vertical;
}

.form-control label {
  margin-bottom: 0.5rem;
}

.form-control input,
.form-control textarea,
.form-control select {
  /* font: inherit; */
  padding: 0.5rem;
  border-radius: 40px;
  border: 1px solid var(--clr-cream-100);
  background-color: var(--clr-white-200);
  width: 100%;
  max-width: 100%;
  height: 2.6rem;
}

.form-control textarea{
  height: 5.6rem;
  max-height: 100%;
  padding: 1rem;
}


.form-control input:focus,
.form-control textarea:focus {
  outline: none;
  border-color: #240370;
  background-color: #e0d4fd;
}

.control-group {
  display: flex;
  column-gap: 1rem;
  flex-wrap: wrap;
}

.form-actions button {
  margin-left: 1rem;
}

.invalid input {
  border: 1px solid #b40e0e;
  background-color: #fddddd;
}

.invalid input:focus {
  border-color: #ff8800;
  background-color: #fbe8d2;
}

.error-text {
  color: #b40e0e;
}
