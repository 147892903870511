.btn {
  padding: 15px 20px;
  color: var(--clr-white-100);
  border: 1px solid var(--clr-cream-100);
  cursor: pointer;
  font-size: 20px;
  background-color: var(--clr-sage-100);
  border-radius: 30px;
  transition: 0.6s;
  font-size : clamp(1rem, 2vw, 1.3125rem);
}

.btn:hover {
  background-color: var(--clr-white-200);
  color: var(--clr-sage-100);
}
