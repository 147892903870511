.navlink {
  margin-inline: 1.8rem;
  color: var(--clr-cream-100);
  cursor: pointer;
  font-size: 1.5rem;
  border-bottom: 4px solid transparent;
  transition: border-color .3s,color .3s;
}

.navlink:hover {
  border-bottom: 4px solid var(--clr-sage-100);
}


@media (max-width: 770px) {

  .navlink {
    margin-inline: 0;
    padding-left: 2rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
    width: fit-content;
  }

}
