:root {
  --clr-sage-100: #98C497;
  --clr-cream-100: #efe9db;
  --clr-cream-200: #DBCEAF;

  --clr-primary-200: #f3eed9;
  /* --clr-primary-400: #483C46; */
  --clr-primary-400: #3D3347;
  --clr-green-100: #BDD4B4;
  --clr-green-200: #7C9474;
  --clr-green-300: #B7C1B3;

  --clr-brown-100: #26170E;

  --clr-white-100: #FFFFFF;
  --clr-white-200: #F0F4F0;


  --clr-neutral-100: #fff;
  --clr-neutral-900: #222c2a;

  /* --ff-primary: "Roboto", sans-serif; */
  --ff-title: 'Noto Serif', serif;
  --ff-primary: 'Lato', sans-serif;
  --ff-accent: "Playfair Display", serif;
  --ff-julius: 'Julius Sans One', sans-serif;
  --ff-josefin: 'Josefin Sans', sans-serif;
}


*, ::before, ::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


body {
  font-family: var(--ff-josefin);
  font-weight: 400;
  font-size: 1.3125rem;
  line-height: 1.6;
}

body,
h1,
h2,
h3,
p {
  margin: 0;
}

h1,
h2,
h3 {
  color: var(--clr-brown-100);

  font-family: var(--ff-julius);
  font-weight: 900;
  line-height: 1;
}

h2 {
  /* font-size: 4.3125rem; */
  /* font-size : clamp(2rem, 3.8rem, 6rem); */
  font-size : clamp(2rem, 10vw, 4.6rem);
  font-weight: normal;
}

h2,
h3,
p {
  margin-bottom: 1em;
  color: var(--clr-brown-100);
}

img {
  display: block;
  max-width: 100%;
}

.text-center {
  text-align: center;
}


header,
section {
  padding: 4rem 0;
  @media (min-width: 57em) {
    header,
    section {
      padding: 7rem 0;
    }
  }
}

.container {
  margin-inline: auto;
  width: min(90%, 70.5rem);
}

.split {
  display: flex;
  flex-direction: column;
}

/* @media (min-width: 40em) { */

@media (min-width: 57em) {
  .split {
    flex-direction: row;
  }

  .split > * {
    flex-basis: 100%;
  }

  .split > * + * {
    margin-left: 2em;
  }
}

.container--narrow {
  max-width: 34rem;
}
