.avatar-me {
  border-radius: 999px;
  width: 18rem;
  height: 35rem;
  object-fit: cover;
  object-position: 20% 50%;
}

.img-author {
  display: flex;
  justify-content: center;
}


@media (max-width: 57em) {
  .img-author{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .text-author {
    text-align: center;
  }

  .split-author {
    flex-direction: column-reverse;
  }
}
