.new-footer{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 6rem;
}

.new-footer ul li {
  list-style-type: none;
  font-size: large;
}

.social-media ul li {
  display: inline-block;
  padding-right: 1rem;
  font-size: 2.5rem;
}

.social-media a {
    color: inherit; /* blue colors for links too */
    text-decoration: inherit; /* no underline */
}

#icon,
#icon-envelopp {
  margin-right: 1rem;
  /* font-size: 1.5rem; */
  cursor: pointer;
}

#icon:hover,
#icon-envelopp:hover {
  color: var(--clr-sage-100);
}

.alert {
  position: absolute;
  bottom: 1rem;
  right: 16.5rem;
}

footer {
  text-align:center;
  margin-top: 2rem;
  padding: 5px;
}


@media (max-width: 690px) {
  .new-footer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    text-align: center;
  }

}
