.card-expertise {
  height: 520px;
  width: min(90%, 410px);
  padding: 2rem;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 1rem;

  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;

}

.card-expertise div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.card-expertise h3 {
  font-size : clamp(1.7rem, 4.5vw, 3rem);

}

.card-expertise img {
  width: 3.5rem;
}

.card-expertise p, .card-expertise h3 {
  color: var(--clr-primary-400);
}

@media (max-width: 57em) {
  .card-expertise {
    height: max-content;
    margin-bottom: 3rem;
  }
  .split-expertise {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
